(function ($) {

    /**
     * Accordions
     */
    var accordions = $('.accordion');
    if(accordions.length) {
        $(accordions).each(function (i) {
            // Find all the bellows in the accordion.
            var bellows = $(accordions[i]).find('.bellow');
            // loop through each of the bellows
            $(bellows).each(function (b) {
                var bellowHeader = $(bellows[b]).find('.bellow-header');
                var bellowBody = $(bellows[b]).find('.bellow-body');
                // listen for a click on each bellow header
                bellowHeader.on('click', function () {
                    $(bellows).each(function (c) {
                        // Check if the current bellow contains the clicked bellow header
                        if(b !== c){
                            if (bellows[c].classList.contains('open')) {
                                $(bellows[c]).find('.bellow-body').slideUp();
                                bellows[c].classList.remove('open');
                            }
                            // Check if the clicked bellow is open
                        } else if (bellows[b].classList.contains('open')) {
                            bellows[b].classList.remove('open');
                            bellowBody.slideUp();
                        } else {
                            bellows[b].classList.add('open');
                            bellowBody.slideDown();
                        }
                    });

                });
            });
        });
    }

})(jQuery); // Fully reference jQuery after this point.